import React, { useEffect, useState } from 'react';

import { Container, Grid } from '@mui/material';
import theme, { Text } from "../theme/theme";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../redux/reducers/DataReducer"
import { useHover } from 'react-hover-animation'
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';

const MenuItem = ({text, small, underline, link, onClick, setOpen})=>{
    const { spring, animated, setHover } = useHover({
 
        animationConfig: {
          duration: 500,
        },
      })    

    const t = <animated.span onClick={()=>{
        setOpen(false);
        onClick();
    }} 
    onPointerOver = {()=>{setHover(true)}}
    onPointerOut = {()=>{setHover(false)}}
    style={{
        fontSize: small?"30px":"38px",
        color: "#EAD369",
        textDecoration: underline?"underline":null,
        fontFamily:  'Fira Sans Extra Condensed',
        paddingLeft: small? "10px":"40px",
        marginTop: "40px",
        cursor: "pointer",
        ...spring
    }}>
         {text} 
    </animated.span>

    return  link? <Link to={link}>{t}</Link>:t
}

const Language = ({language, setOpen})=>{
    const dispatch = useDispatch();
    const current = useSelector(state=>state.data.language)

    

    return <MenuItem text={language} underline={current==language} small setOpen={setOpen} onClick={()=>{
        dispatch({ type: DATA_ACTIONS.SET_LANGUAGE, language});
    }} />
}

export const Header = ()=>{
    const settings = useSelector(state=>state.data.settings);
    const location = useLocation();
    const [open, setOpen] = useState(false);
    

    if (!settings){
    return null
    }

    const large = <div style={{
        background: "white",
        padding: "20px",
        borderBottom: "2px solid #EAD369",
        position: "fixed",
        paddingTop: "15px",
        width: "100%",
        textAlign: "center",
        zIndex: "10"
      }}>
          <span>
              <img src={settings.logos.typo} height="45px" style={{transform: "translateY(+12px)", float: "left"}} />
              <div style={{display: "inline-block", paddingTop: "10px"}}>
                  <MenuItem text={"HOME"} link="/" underline={("/" == location.pathname)} setOpen={setOpen}/>
                  <MenuItem text={"PROJECTS"} link="/projects" underline={(location.pathname.indexOf("/projects") >= 0)} setOpen={setOpen}/>
                  <MenuItem text={"SUPPORT US"} link="/support" underline={("/support" == location.pathname)} setOpen={setOpen}/>
                  <MenuItem text={"CONTACT"} link="/contact" underline={("/contact" == location.pathname)} setOpen={setOpen}/>
              </div>
  
              <a style={{paddingTop: "10px", float: "right", marginRight:"50px", marginTop:"5px"}}>
                  <Language language={"EN"} setOpen={setOpen}/>
                  <Language language={"NL"} setOpen={setOpen}/>
                  <Language language={"FR"} setOpen={setOpen}/>
              </a>
          </span>
      </div>

const small = <div style={{
    background: "white",
    borderBottom: "2px solid #EAD369",
    position: "fixed",
    paddingTop: "10px",
    minHeight: "70px",
    width: "100%",
    textAlign: "center",
    zIndex: "10",
    cursor: "pointer"

  }}>
      <span>
          <img src={settings.logos.typo} height="45px" style={{transform: `translate(${open?"20px":"45px"}, +9px)`}} onClick={()=>{setOpen(!open)}} />
          <MenuIcon style={{color: "#EAD369", float: "right", fontSize: "40px", padding: "10px"}}  onClick={()=>{setOpen(!open)}} />
          {open? <div>
            <br/><br/>
            <div style={{transform: "translate(-20px, 0)"}}>
            <MenuItem text={"HOME"} link="/" underline={("/" == location.pathname)} setOpen={setOpen}/> <br/>
                  <MenuItem text={"PROJECTS"} link="/projects" underline={("/projects" == location.pathname)} setOpen={setOpen}/> <br/>
                  <MenuItem text={"SUPPORT US"} link="/support" underline={("/support" == location.pathname)} setOpen={setOpen}/> <br/>
                  <MenuItem text={"CONTACT"} link="/contact" underline={("/contact" == location.pathname)} setOpen={setOpen}/><br/> 

            <div style={{width: "120%", marginTop: "10px", marginBottom: "20px",  borderBottom: " solid #EAD369 3px"}} >
                &nbsp;
            </div>

                <Language language={"EN"} setOpen={setOpen}/>
                  <Language language={"NL"} setOpen={setOpen}/>
                  <Language language={"FR"} setOpen={setOpen}/>

            </div>
            

          </div>:null
        }
          <div style={{display: "inline-block", paddingTop: "10px"}}>
              {/* <MenuItem text={"HOME"} link="/" underline={("/" == location.pathname)} />
              <MenuItem text={"PROJECTS"} link="/projects" underline={("/projects" == location.pathname)}/>
              <MenuItem text={"SUPPORT US"} link="/support" underline={("/support" == location.pathname)}/>
              <MenuItem text={"CONTACT"} link="/contact" underline={("/contact" == location.pathname)}/> */}
          </div>

          <a style={{paddingTop: "10px", float: "right", marginRight:"50px", marginTop:"5px"}}>
              
          </a>
      </span>
  </div>



    return <div>
        <Box sx={{ display: { xs:'none', sm: 'none', md: 'block' } }}>
        {large}
        </Box>
        <Box sx={{ display: { sm: 'block', md: 'none' } }}>
              {small}
        </Box>
    </div>
  
  }
  