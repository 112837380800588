
  export const projectQuery = `
  *[_type=="project"]{
    title,
    urlname,
    _id,
    images[]{
      _key,
      "img":asset->url
    },
    shortdescription,
    longdescription,
    youtube,
    starred
  }
  `

  export const settingsQuery = `
    *[_type=="settings"][0]{
      "footer": general.footer,

      "contact" : {
        "intro" : general.contact.intro,
        "img" : general.contact.img.asset->url
      },

      "home": {
        "img" : general.home.img.asset->url,
        "members" : general.home.members[]{
          _key,
          title,
          description,
          "img": img.asset->url
        },
        "mission" : general.home.mission,
        "missionimages" : general.home.missionimages[] {
          _key,
          "img" : asset->url
        },
        "partnertext" : general.home.partnertext
      },

      "partners" : general.partners[]{ 
        _key,
        footer,
        url,
        "img": img.asset->url
      },

      "logos": {
        "full" : general.fulllogo.ing.asset->url,
        "typo" : general.typologo.img.asset->url
      },

      "projects" : general.projects,

      "support" : {
        "intro" : general.support.intro,
        "img" : general.support.img.asset->url
      }
    }
  `
  
//   export const redShiftQuery = `*[_type == "redshift" && _id=="redshift"][0]{
//     intro{
//         title,
//         description,
//         "img" : img.asset->url
//   },
//     segments[]{
//       title,
//       description,
//       resources[]{
//           _type,
//               _key,
//         title,
//         "description": lineDescription,
//         "file" : file.asset->url,
//         url
        
//       }
//     },
//       exit{
//             title,
//             description,
//             "img" : img.asset->url,
//             "img2" : img2.asset->url
//           },
//     footer {
//       description,
//          partners[]{
//          url,
//          "img" : img.asset->url,
//          _key
//        }
//     }
//   }`


//   export const homeQuery =  `
//   *[_type == "settings"][0]{
//       title,
//       subtitle,
//       intro
//     }
//     `

// export const SchoolQuery = `
// *[_type == "siteSettings"][0]{
//     title,
//     intro1,
//     intro2,
//     footer,
//     logos[]{
//       _key,
//       "img":img.asset->url
//     },
//     projects[]->{
//         _id,
//       authors[]{
//         _key,
//         title,
//         "avatar": avatar.asset->url
//       },
//       "background":background.asset->url,
//       color,
//       feedback,
//       "heading":heading.asset->url,
//       "logo":logo.asset->url,
//       segments[]{
//         _key,
//         title,
//         description,
//         supportingGraphics[]{
//           _key,
//           description,
//           title,
//           "img":img.asset->url
//         }
//       },
//       title,
//       longtitle,
//       tagline
//     }
//   }
  
  
// `