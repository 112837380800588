import React, { useEffect, useState } from 'react';

import { Container, Grid } from '@mui/material';
import theme, { Text } from "../theme/theme";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../redux/reducers/DataReducer"
import BlockContent from '@sanity/block-content-to-react';


export const LanguageText = ({text, color})=>{
    const lang = useSelector(state=>state.data.language);

    if (!lang){
    return null
    } 

    return <BlockContent blocks={text[lang.toLowerCase()]} />
  }
  