import React, { useEffect, useState } from 'react';
import { Box, Title, SubTitle, Text, Button, Space, TextSection, HoverableImage} from '../theme/theme';
import { Header } from '../components/header';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import Spinner from '../components/spinner';
import { Container, Grid } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react'

import settings from "../settings.json"
import { Link } from 'react-router-dom';
import { AvatarList } from '../components/avatar';
import { Satellite } from '@mui/icons-material';

// TODO: implement

const Home = ()=>{
    // const [data, setData] = useState(undefined);
    const language = useSelector(state=>state.data.language)
    const client = useSelector(state=>state.data.client);
    const settings = useSelector(state=>state.data.settings);

    // useEffect(()=>{
    //     if (!data && client){
    //         client.getSettings()
    //         .then(newdata=>setData(newdata));
    //     }
    // })

    const Meta = <MetaTags>
                    <title>{"SOUTURO overview"}</title>
                    <meta name="description" content={" Souturo proposes a social entrepreneurship business model around the banana plantation of Bantantinty to make a change in the village."} />
                    <meta property="og:title" content={"SOUTURO / Overview"} />
                </MetaTags>
    
    if(!settings){
        return null
    }
    
    
    return <>
            {Meta}
            
            <br/><br/><br/><br/>
            <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={false} lg={10} md={11} sm={12}>
                            <Box>
                                <Grid item md={0}>
                                    Current language: {language}
                                    <Button to="/home">
                                        Hallo
                                    </Button>
                                    <Title color="white">
                                        hello
                                    </Title>

                                    <TextSection text={settings.contact.intro} title="TITLE" />

                                    <HoverableImage src="https://i.pinimg.com/originals/b0/5b/70/b05b70b3edbcb5b2a83bf97fc4b8a688.jpg" />
                                    
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>   

</>
    
}



export default Home;
