import React, { useEffect, useState } from 'react';

import { Container, Grid } from '@mui/material';
import theme, { Text, Box } from "../theme/theme";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../redux/reducers/DataReducer"
import 'animate.css';
import { LanguageText } from './LanguageText';


export const Footer = ()=>{
    const settings = useSelector(state=>state.data.settings);
    

    if (!settings){
    return null
    }



    return <Grid container spacing={2} style={{background: "#80D3DD", width: "105%", zIndex: "10"}}>
            <Grid item xs={11} sm={3} md={2} lg={2} >
                <img src={settings.logos.full} width="100%" style={{padding: "20px", float: "left"}} />
            </Grid>
            <Grid item xs={10} sm={8} md={3} lg={3} style={{marginLeft:"50px"}}>
                    <LanguageText text={settings.footer.section1} />
            </Grid>
            <Grid item xs={10} sm={6} md={3} lg={3} style={{marginLeft:"50px"}}>
                <LanguageText text={settings.footer.section2} />
            </Grid>
            <Grid item xs={10} sm={3} md={2} lg={2} style={{marginLeft:"100px", marginTop: "20px"}}>
                {settings.partners.filter(p=>p.footer).map(({_key, url, img})=><img src={img} key={_key} width="75%" style={{padding:"10px", paddingRight: "50px"}} />)}
            </Grid>
        </Grid>
  }
  