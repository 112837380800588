
import {createTheme, Typography, responsiveFontSizes } from "@mui/material";
import {default as MButton} from "@mui/material/Button"
import { Link } from "react-router-dom";
import { LanguageText } from "../components/LanguageText";
import "./index.css"

const defaultTheme = {
    palette: {
      type: 'light',
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '#ffff',
      },
    },
    typography: {
      fontFamily: 'Open sans',
      fontSize: 14,
      h1: {
        fontWeight: 600,
        fontSize: '6rem',
      },
      h2: {
        fontSize: '3.8rem',
        fontWeight: 100,
        fontFamily: 'Fira Sans Extra Condensed'
      },
      h3: {
        fontWeight: 1000,
        fontSize: '2.7rem',
      },
    },
    components: {
        MuiButton: {
          variants: [
            {
              props: { variant: 'hard' },
              style: {
                textTransform: 'none',
                border: `2px solid black`,
                borderRadius: "0px"
              },
            },
          ],
        },
      },
  };

  

const theme =  responsiveFontSizes(createTheme(defaultTheme));


//   typography

export const SuperTitle = ({children, color})=>{
  return <div style={{fontFamily: 'Fira Sans Extra Condensed', fontWeight: "bold", color:color?color:"white", fontSize: "80px"}}> 
    {children}
  </div>
}

export const Title = ({children, color})=>{
    return <div style={{fontFamily: 'Fira Sans Extra Condensed', fontWeight: "bold", color:color?color:"#EAD369", fontSize: "50px"}}> 
      {children}
    </div>
}

export const SubTitle = ({children})=>{
    return <Typography variant="h2">{children}</Typography>
}

export const Text = ({children})=>{
  return <Typography component={'span'} variant="body1">{children}</Typography>
}

export const TextSection =({text, title, children})=>{
  return <Box backgroundColor={"white"}>
      <Title>{title}</Title>
      <LanguageText text={text} />
      {children}
  </Box>
}


// button
export const Button = ({children, to, color, external})=>{
  color = color?color:defaultTheme.palette.primary;
  const B = <a style={{fontFamily: 'Fira Sans Extra Condensed', fontSize: "33px", color:"#EAD369", backgroundColor:"white", border: " solid 4px #80D3DD", padding: "10px", margin: "20px"}}>
    {children}
    </a>


  if (to){
    if (external){
      return <a href={to} target="_blank">{B}</a>
    } else {
      return <Link to={to}>
        {B}
    </Link>
    }
  } else {
    return B;
  }
}

//   media 
export const ResponsiveImage = ({src})=>{
    return <img src={src} style={{width: "100%"}} />
}

export const HoverableImage = ({src})=>{
  return <img src={src} style={{width: "100%"}} className="hoverableImage" />
}


export const Space = ()=><>&nbsp;</>

// contianers
export const Box = ({children, backgroundColor, noTop, noBottom})=>(
  <div className="box" style={{backgroundColor: backgroundColor?backgroundColor:null, marginTop: noTop?"0px":null,  marginBottom: noBottom?"0px":null}}>
    {children}
  </div>
)
  
export default theme;

