import React, { useEffect, useState } from 'react';
import { Box, Title, SubTitle, Text, Button, Space, HoverableImage, TextSection, SuperTitle } from '../theme/theme';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import Spinner from '../components/spinner';
import { Container, Grid } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react'

import settings from "../settings.json"
import { Link } from 'react-router-dom';
import { AvatarList } from '../components/avatar';
import { PieChartOutlineSharp } from '@mui/icons-material';

// TODO: implement

const Contact = ()=>{
    
    const data = useSelector(state=>state.data.settings);
    const projects = useSelector(state=>state.data.projects);

    const Meta = <MetaTags>
                    <title>{"SOUTURO contact"}</title>
                    <meta name="description" content={" Souturo proposes a social entrepreneurship business model around the banana plantation of Bantantinty to make a change in the village."} />
                    <meta property="og:title" content={"SOUTURO / Contact"} />
                </MetaTags>

    if (!data){
    return <>{Meta}</>
    } 
    
    
        return <>
                {Meta}
                
                <br/><br/><br/><br/>
                <Container>
                        <Grid container spacing={2}    justifyContent="center">
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextSection text={data.contact.intro} title={"Get in touch"}/>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box noTop noBottom>
                                <HoverableImage src={data.support.img} />
                                </Box>
                            </Grid>
                            
                            
                            <Grid item xs={12}>
                                <br/><br/><br/>
                            </Grid>
                            
                                
                            
                        </Grid>
                    </Container>   

    </>
    
}



export default Contact;
