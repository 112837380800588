import React, { useEffect, useState } from 'react';
import { Box, Title, SubTitle, Text, Button, Space, HoverableImage, TextSection, SuperTitle } from '../theme/theme';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import Spinner from '../components/spinner';
import { Container, Grid } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react'

import settings from "../settings.json"
import { Link } from 'react-router-dom';
import { AvatarList } from '../components/avatar';
import { PieChartOutlineSharp } from '@mui/icons-material';

// TODO: implement

const Home = ()=>{
    
    const data = useSelector(state=>state.data.settings);
    const projects = useSelector(state=>state.data.projects);

    const Meta = <MetaTags>
                    <title>{"SOUTURO home"}</title>
                    <meta name="description" content={" Souturo proposes a social entrepreneurship business model around the banana plantation of Bantantinty to make a change in the village."} />
                    <meta property="og:title" content={"SOUTURO / Home"} />
                </MetaTags>

    if (!data){
    return <>{Meta}</>
    } 
    
    
        return <>
                {Meta}
                <HoverableImage src={data.home.img} />
                <br/><br/><br/><br/>
                <Container>
                        <Grid container spacing={2}    justifyContent="center">
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextSection text={data.home.mission} title={"OUR MISSION"}/>
                            </Grid>
                            
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                {data.home.missionimages.map((i)=>{
                                return <Box key={i._key} noTop noBottom>
                                    <HoverableImage src={i.img} />
                                </Box>
                                })}
                            </Grid>
                            <Grid item xs={12}>
                                <br/><br/><br/>
                            </Grid>
                            <Grid item xs={12} style={{textAlign: "center"}}>
                                <SuperTitle>Our team</SuperTitle>
                            </Grid>
                                {data.home.members.map(({_key, title, description, img})=>{
                                    return <Grid item xs={12} sm={12} md={6} lg={6} key={_key}>
                                    <Box>
                                        <img src={img} style={{borderRadius: "50%", width: "50%", marginLeft: "25%"}} />
                                        <TextSection text={description} title={title} />
                                    </Box>
                                    </Grid>
                                })}
                            <Grid item xs={12} style={{textAlign: "center"}}>
                                <br/><br/><br/><br/>
                                <SuperTitle>Selected projects</SuperTitle>
                                <TextSection text={data.projects.intro} title={""} />
                            </Grid>
                            { projects.filter(p=>{
                                let rv = false;
                                data.projects.featured.forEach(pr => {
                                    if (pr._ref == p._id){
                                        rv = true;
                                    }
                                });
                                return rv;
                            }).map(({title, urlname, _id, images, shortdescription})=>{
                                 return <Grid item xs={12} sm={12} md={6} lg={6} key={_id}>
                                    <a href={"/projects/"+urlname}>
                                 <Box >
                                     <img src={images[0].img} style={{marginLeft: "125px", objectFit: "cover", width: "250px", height: "250px", border: "solid 5px #80D3DD"}} className="hoverableImage" />
                                     <TextSection text={shortdescription} title={title} />
                                 </Box>
                                 </a>
                                 </Grid>
                                 
                                    
                                }) }

                                <Grid item xs={12}></Grid>

                                

                                <Grid item xs={12} sm={12} md={4}>
                                    <br/><br/><br/>
                                    <Button to="/projects"> 
                                        ☞ View all projects
                                    </Button>
                                    <br/><br/><br/><br/><br/>
                                </Grid>


                            
                                <TextSection text={data.home.partnertext} title={"Partners"} />
                                
                                {data.partners.map(({_key, url, img})=> <Grid key={_key} item xs={12} sm={12} md={6} lg={6}> <a href={url}  target="_blank"> 
                                    <img src={img}  width="60%" style={{marginLeft: "15%"}} />
                                </a></Grid>)}

                                <Grid item xs={12}>
                                <br/><br/><br/><br/>
                                </Grid>

                                

                                
                                
                                
                            
                        </Grid>
                    </Container>   

    </>
    
}



export default Home;
