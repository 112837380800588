import sanityClient from '@sanity/client'
import envi from './environment'
import settings from './connector_settings.json'
import { ACTIONS as DATA_ACTIONS } from "../redux/reducers/DataReducer"
import imageUrlBuilder from '@sanity/image-url'
import { settingsQuery, projectQuery } from './queries'
import { queries } from '@testing-library/react'
const prevFetched = {};

export const client = (dispatch)=>{
    const environment = envi();
    let _ = settings.settings;
    let news = [];
    const sc = sanityClient({
        projectId: _.projectId,
        dataset: _.dataset,
        token: _.token, // or leave blank to be anonymous user
        ignoreBrowserTokenWarning: true,
        useCdn: false,
        apiVersion:'2021-08-31'
    })

    const fetch = (query)=>{
        return new Promise((resolve, reject)=>{
            if (prevFetched[query]){
                if (environment.dev){
                    console.log("reused cached query");
                }
                resolve(prevFetched[query]);
            } else {
                sc.fetch(query)
                .then((data)=>{
                    prevFetched[query] = data;
                    resolve(data);
                }).catch(reject);
            }
        })
    }

    const builder = imageUrlBuilder(sc)

    const squareImage = (url, width)=>{
        return builder.image(url).width(width).height(width).url();
    }

    
    if (environment.dev){
        environment.printstatus()
    } 

    const getUrl = (selector)=>{
        return `"img" : ${selector}.asset->url`
    }

    const generateQuery =(query)=>{
        return new Promise((resolve, reject)=>{
            fetch(query)
            .then(data=>{
                resolve(data)
            })
      });
    }

    const getSettings = () => {
        return generateQuery(settingsQuery)
    }

    const getProjects = () => {
        return generateQuery(projectQuery)
    }

    const uploadImage = (filename, blob)=>{
        return sc.assets.upload('image', blob, {contentType: 'image/png', filename})
    }

    const makeImageReference = (_ref)=>{
        return  {
            _type: 'image',
            asset : {
                _type: "reference",
                _ref
            }
        }
    }

    return {
        fetch,
        environment,
        squareImage,
        getSettings,
        getProjects
    }
}



export default client;

