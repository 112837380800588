
import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Spinner = ()=>(
    <Box sx={{ display: 'flex' }} style={{textAlign: "center", marginTop: "40px", alignItem: "center", justifyContent: "center"}}>
        <CircularProgress />
    </Box>
)

export default Spinner;

