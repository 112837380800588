import { Container, Grid } from '@mui/material';
import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, HoverableImage, Title, Text, SuperTitle, TextSection } from '../theme/theme';
import MetaTags from 'react-meta-tags';
import { useParams } from "react-router-dom";
import Redshift from "../components/redshift"
import { AvatarList } from '../components/avatar';
import Spinner from '../components/spinner';
import { Member } from '../components/member';

// TODO: implement

const Project = ({title, images, longdescription, youtube})=>{

    return <div>
            <MetaTags>
            <title>{`SOUTURO / ${title}`}</title>
            <meta property="og:title" content={`SOUTURO / ${title}`} />
            </MetaTags>
            <HoverableImage src={images[0].img} />
            
            <Box>
                <SuperTitle>{title}</SuperTitle>
            </Box>

            <Grid container spacing={2} justifyContent="center" >
            <Grid item xs={12} sm={12} md={6} lg={6}>

                {youtube?<Box>
                    <iframe width="100%" height="315"
                        src={youtube}>
                    </iframe>
                </Box>:null}

                <TextSection text={longdescription}/>
                
                


            </Grid>                
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    {images.map((i)=>{
                    return <Box key={i._key} noTop noBottom>
                        <HoverableImage src={i.img} />
                    </Box>
                    })}
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
                                    <br/><br/><br/>
                                    <Button to="/projects"> 
                                        ☞ View all projects
                                    </Button>
                                    <br/><br/><br/><br/><br/>
                                </Grid>
            
    </div>
    
}

const Projects = (props) => {
    const [project, setProject] = useState(null);
    const params = useParams();
    const projects = useSelector(state=>state.data.projects);
    const data = useSelector(state=>state.data.settings);
    const [ordered, setOrdered] = useState(null);
    

    useEffect(()=>{
        if (params){
            if (!project && params.name && projects){
                projects.forEach(p => {
                    if (p.urlname.toLowerCase() == params.name){
                        setProject(p);
                        console.log(p)
                    } 
                });
            }
        }

        if (!ordered && data && projects){
            let projs = projects.reduce(function(map, obj) {
                map[obj._id] = obj
                return map;
            }, {});
            let rv = [];
            data.projects.portfolio.forEach(({_ref})=>{
                rv.push(projs[_ref])
            })
            setOrdered(rv)
        }

    })

    
    if (!projects || !ordered){
        return <Spinner/>
    } else if (project){
        return <Project title={project.title} images={project.images} longdescription={project.longdescription} youtube={project.youtube} starred={project} />
    } else {
        return   <>
        <MetaTags>
            <title>{"SOUTURO / Projects"}</title>
            <meta property="og:title" content={"SOUTURO / Projects"} />
            </MetaTags>
            <Container>
                <Grid container spacing={2}>
                { ordered.map(({title, urlname, _id, images, shortdescription, starred})=>{
                                 return <Grid item xs={12} sm={12} md={starred?12:6} lg={starred?12:6} key={_id}>
                                    <a href={"/projects/"+urlname}>
                                 <Box >
                                     <img src={images[0].img} style={{marginLeft: starred?null: "125px", objectFit: "cover", width: starred?"100%":"250px", height: "250px", border: "solid 5px #80D3DD"}} className="hoverableImage" />
                                     <TextSection text={shortdescription} title={title} />
                                 </Box>
                                 </a>
                                 </Grid>
                                 
                                    
                                }) }

                </Grid>
            </Container>   
        </>
    }
}

export default Projects;
